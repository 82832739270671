export default ({
  title,
  message,
  callback = () => {},
  confirmButtonText = 'Sim, remover',
  cancelButtonText = 'Cancelar',
  showLoaderOnConfirm = true,
  showCancelButton = true,
  confirmButtonColor = '#d33',
  ...rest
}) => {
  window.swal.fire({
    title,
    html: message,
    showCancelButton,
    confirmButtonText,
    cancelButtonText,
    preConfirm: () => callback().catch(() => window.swal.close()),
    showLoaderOnConfirm,
    confirmButtonColor,
    ...rest,
  });
};
